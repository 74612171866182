import { copyToClipboard } from '../../utils/jsBridge';

// 复制文本内容到粘贴板
export const copyLinkToClipboard = (text) => {
	return new Promise((resolve) => {
		try {
			copyToClipboard({ text }, (result) => {
				resolve(/true/i.test(result));
			});
		} catch (err) {
			console.error('[copyLinkToClipboard: ]', err);
			resolve(false);
		}
	});
};

// 保存页面信息到localStorage
export const saveInfoToLocalStore = (info) => {
	if (info && typeof info === 'object') {
		localStorage.setItem('invitationInfo', JSON.stringify(info));
	} else {
		localStorage.setItem('invitationInfo', info);
	}
};

// 从localStorage中获取邀请页存储的数据
export const getInfoFromLocalStore = (defaultValue = {
	invited_days: 0,
	invited_nums: 0,
	invited_join_days: 0,
	join_vip_nums: 0,
}) => {
	try {
		const info = localStorage.getItem('invitationInfo');
		return info ? JSON.parse(info) : defaultValue;
	} catch (err) {
		console.error('[getInfoFromLocalStore:]', err);
		return defaultValue;
	}
};